import React, { useRef, useEffect } from "react";
import { Rnd } from "react-rnd";

type DialogWindowProps = {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  initialWidth?: number;
  initialHeight?: number;
  minWidth?: number;
  minHeight?: number;
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  trigger?: React.ReactNode;
};

const DialogWindow: React.FC<DialogWindowProps> = ({
  isVisible,
  onClose,
  title,
  initialWidth = 600,
  initialHeight = 500,
  minWidth = 400,
  minHeight = 300,
  children,
  headerContent,
  className = "",
  contentClassName = "",
  trigger,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    if (isVisible && !dialog.open) {
      dialog.showModal();
    } else if (!isVisible && dialog.open) {
      dialog.close();
    }
  }, [isVisible]);

  return (
    <>
      {trigger}
      {isVisible && (
        <dialog
          ref={dialogRef}
          className={`fixed inset-0 w-screen h-screen p-0 m-0 border-none bg-transparent backdrop:bg-black/50 ${className}`}
          onClose={onClose}
        >
          <div ref={containerRef} className="w-full h-full">
            <Rnd
              default={{
                x: (window.innerWidth - initialWidth) / 2,
                y: (window.innerHeight - initialHeight) / 2,
                width: initialWidth,
                height: initialHeight,
              }}
              minWidth={minWidth}
              minHeight={minHeight}
              bounds="parent"
              dragHandleClassName="dialog-handle"
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: true,
                bottomRight: true,
                bottomLeft: true,
                topLeft: true,
              }}
            >
              <div
                className={`bg-white shadow-lg h-full overflow-hidden rounded-lg ${contentClassName}`}
              >
                <div className="dialog-handle flex justify-between items-center p-4 border-b cursor-move bg-gray-50 select-none">
                  <div className="flex items-center gap-4">
                    <h2 className="text-lg font-semibold">{title}</h2>
                    {headerContent}
                  </div>

                  <button
                    type="button"
                    className="p-1 hover:bg-gray-200 rounded-full"
                    onClick={onClose}
                  >
                    ✕
                  </button>
                </div>

                <div className="w-full h-[calc(100%-60px)] overflow-auto">
                  {children}
                </div>
              </div>
            </Rnd>

            <style>
              {`
                .rnd-resize-handle {
                  position: absolute;
                  opacity: 0;
                  transition: opacity 0.2s;
                }
                .rnd-resize-handle:hover {
                  opacity: 0.2;
                  background-color: rgb(191 219 254);
                }
              `}
            </style>
          </div>
        </dialog>
      )}
    </>
  );
};

export default DialogWindow;
