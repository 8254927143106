import React from "react";

import { passwordRequirementsMessage } from "@spesill/utils/ValidatePassword";

export const PasswordRequirements: React.FC = () => {
  return (
    <div className="text-blueGray-300 text-sm">
      {passwordRequirementsMessage.map((line: string, index: number) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );
};
