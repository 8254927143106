import React from "react";

import { Icon } from "@spesill/components/atoms";

interface DownloadButtonProps {
  downloadFile: () => void;
  className?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  downloadFile,
  className,
}) => (
  <button
    type="button"
    onClick={downloadFile}
    className={`group relative px-6 py-3 bg-blue-500 text-white rounded-lg
        shadow-lg hover:bg-blue-600 hover:shadow-xl
        transform transition-all duration-200 hover:-translate-y-0.5
        flex items-center justify-center gap-2 font-medium ${className}`}
  >
    <Icon icon="bsDownload" size="1.2rem" color="text-white" />
    ダウンロード
  </button>
);

export default DownloadButton;
