import { useCallback } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

import { privateStorage } from "@spesill/config/firebase";
import { getBlob, ref } from "@spesill/libs/firebase";

export const useStorageLoadGLBFromStorage = () => {
  const loadGLB = useCallback(
    async (storagePath: string): Promise<THREE.Group> => {
      try {
        const modelRef = ref(privateStorage, storagePath);
        const blob = await getBlob(modelRef);

        const arrayBuffer = await blob.arrayBuffer();

        return new Promise((resolve, reject) => {
          const loader = new GLTFLoader();
          loader.parse(
            arrayBuffer,
            "",
            (gltf: GLTF) => {
              resolve(gltf.scene);
            },
            (error) => {
              reject(error);
            },
          );
        });
      } catch (error) {
        console.error("Error loading GLB:", error);
        throw error;
      }
    },
    [],
  );

  return { loadGLB };
};
