import { Entity } from "./__common__/entity";
import { SourceType } from "./source";

export type ChatRoomMessageSenderType = "USER" | "AI";
export type FileType = "image" | "text" | "glb" | "unknown";

export class ChatRoomMessage extends Entity {
  id: string;
  message: string;
  sender: ChatRoomMessageSenderType;
  timestamp: Date;
  sources: SourceType[];

  constructor(arg: ExcludeMethods<ChatRoomMessage>) {
    super(arg);

    this.id = arg.id;
    this.message = arg.message;
    this.sender = arg.sender;
    this.sources = arg.sources;
    this.timestamp = arg.timestamp;
  }

  static getFileType(storagePath: string): FileType {
    // パスから拡張子を取得（小文字に変換して処理）
    const extension = storagePath.split(".").pop()?.toLowerCase() || "";

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg", "bmp"];
    if (imageExtensions.includes(extension)) {
      return "image";
    }

    const textExtensions = [
      "txt",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
    ];
    if (textExtensions.includes(extension)) {
      return "text";
    }

    // GLBファイル
    if (extension === "glb") {
      return "glb";
    }

    return "unknown";
  }
}
