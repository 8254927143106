import { v4 } from "uuid";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  deleteDoc,
  QueryConstraint,
  OrderByDirection,
  orderBy,
  updateDoc,
} from "@spesill/libs/firebase";
import { LearningDocument } from "@spesill/models/learningDocument";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class LearningDocumentRepository extends DBRepositoryBase<
  ExcludeMethods<LearningDocument>
> {
  private PATH = "learningDocuments";

  add = async (
    document: Omit<
      ExcludeMethods<LearningDocument>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const docId = v4();
    const newPage = {
      ...document,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
      deletedAt: undefined,
      learnedAt: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newPage));

    return { id: docId };
  };

  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      return new LearningDocument(this.docToObject(document));
    }
  };

  findByTenantIdAndId = async (
    tenantId: string,
    learningDatabaseId: string,
    id: string,
  ) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
      where("learningDatabaseId", "==", learningDatabaseId),
      where("__name__", "==", id),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs[0]) {
      throw new Error("ドキュメントが見つかりませんでした");
    }
    return new LearningDocument(this.docToObject(docs[0]));
  };

  findByTenantIdAndLearningDatabaseId = async (
    tenantId: string,
    learningDatabaseId: string,
    filter?: {
      createUserId?: string;
    },
    sort?: {
      field: "updatedAt";
      order: OrderByDirection;
    },
  ) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("learningDatabaseId", "==", learningDatabaseId),
    ];
    if (filter?.createUserId) {
      queryConstraints.push(where("createUserId", "==", filter.createUserId));
    }
    if (sort) {
      queryConstraints.push(orderBy(sort.field, sort.order));
    }
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("ドキュメントが見つかりませんでした");
    }

    return docs.map((doc) => new LearningDocument(this.docToObject(doc)));
  };

  findByTenantIdAndLearningDatabaseIds = async (
    tenantId: string,
    learningDatabaseIds: string[],
  ) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
      where("learningDatabaseId", "in", learningDatabaseIds),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("ドキュメントが見つかりませんでした");
    }

    return docs.map((doc) => new LearningDocument(this.docToObject(doc)));
  };

  deleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);
    // 論理削除を実装する場合条件に追加 → || !document.data()?.deletedAt
    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await deleteDoc(ref);
    }
  };

  learnedById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);
    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, { learnedAt: new Date() });
    }
  };
}
