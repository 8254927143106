/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { Button, FormSubmitButton } from "@spesill/components/atoms";

import { useCurrentUser, useFetchLearningDocuments } from "@spesill/hooks";
import { useLearningDocumentsByKeywords } from "@spesill/hooks/firestore/learningDocuments/useLearningDocumentKeywords";
import { LearningDatabase, LearningDocument } from "@spesill/models";

import { DocumentItem } from "./DocumentItem/DocumentItem";
import Preview from "./Preview";

type PropsType = {
  selectedDB: LearningDatabase[];
  selectedDocuments: LearningDocument[];
  onChangeDocuments: (learningDocument: LearningDocument) => void;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
};

const SHOW_KEYWORDS_LIMIT = 9;

export const SelectFile = ({
  selectedDB,
  selectedDocuments,
  onChangeDocuments,
  onClose,
  onBack,
  onSubmit,
  isLoading,
}: PropsType) => {
  const { currentUser } = useCurrentUser();
  const { documents } = useFetchLearningDocuments(
    selectedDB.map((db) => db.id),
  );
  const [previewDocument, setPreviewDocument] = useState<{
    dbName: string;
    document: LearningDocument;
  }>();
  const [isKeywordsDisplay, setIsKeywordsDisplay] = useState<boolean>(false);

  const {
    keywords,
    selectedKeywords,
    isLoadingKeywords,
    isLoadingDocuments,
    isShowMoreKeywords,
    filteredDocuments,
    setIsShowMoreKeywords,
    onChangeKeyword,
  } = useLearningDocumentsByKeywords({
    selectedDB,
    documents,
    tenantId: currentUser?.tenantId || "",
  });

  if (previewDocument) {
    return (
      <Preview
        dbName={previewDocument.dbName || ""}
        document={previewDocument.document}
        onBack={() => setPreviewDocument(undefined)}
      />
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <h4 className="text-h4 text-blueGray-900 px-3">文章を作成</h4>
      <div className="pt-8 px-3">
        <div className="flex flex-row justify-between items-center pb-6">
          <h5 className="text-lg font-bold text-blueGray-900">
            参考にしたいファイルを選択してください
          </h5>
        </div>
        {!isKeywordsDisplay ? (
          <div className="mb-6">
            <button
              type="button"
              className="flex flex-row gap-x-1 items-center mb-2 hover:opacity-60 transition-opacity duration-300"
              onClick={() => setIsKeywordsDisplay(true)}
            >
              <span className="text-blueGray-500 text-sm">▶︎</span>
              <span className="text-blueGray-500 text-sm">
                キーワードでファイルを絞り込む
              </span>
            </button>
          </div>
        ) : (
          <div className="mb-6">
            <button
              type="button"
              className="flex flex-row gap-x-1 items-center mb-2"
              onClick={() => setIsKeywordsDisplay(false)}
            >
              <span className="text-blueGray-500 text-sm">▼</span>
              <span className="text-blueGray-500 text-sm">
                キーワードを選択
              </span>
            </button>
            <div className="flex flex-row flex-wrap gap-2 items-center">
              {isLoadingKeywords ? (
                <div className="text-blueGray-400 text-sm">
                  キーワードを取得中...
                </div>
              ) : keywords.length > 0 ? (
                keywords
                  .slice(
                    0,
                    isShowMoreKeywords ? keywords.length : SHOW_KEYWORDS_LIMIT,
                  )
                  .map((keyword) => (
                    <button
                      key={keyword}
                      type="button"
                      className={`px-4 py-1 rounded-full border border-blueGray-200 text-sm ${selectedKeywords.includes(keyword) ? "bg-primary-800 border-primary-800 text-white" : ""}`}
                      onClick={() => onChangeKeyword(keyword)}
                      disabled={isLoading}
                    >
                      {keyword}
                    </button>
                  ))
              ) : (
                <div>関連するキーワードはありません。</div>
              )}
              {!isShowMoreKeywords && keywords.length > SHOW_KEYWORDS_LIMIT && (
                <button
                  type="button"
                  className="text-sm text-primary-400"
                  onClick={() => setIsShowMoreKeywords(true)}
                >
                  関連するキーワードをさらに表示
                </button>
              )}
            </div>
          </div>
        )}
        <div className="pb-8 flex flex-col gap-y-2 max-h-96 overflow-y-auto">
          {isLoadingDocuments ? (
            <div className="text-sm text-blueGray-400">ファイルを取得中...</div>
          ) : selectedKeywords.length > 0 && documents.length === 0 ? (
            <div>関連するファイルがありません</div>
          ) : (
            filteredDocuments.map((document) => (
              <DocumentItem
                key={`file-${document.id}`}
                learningDocument={document}
                isLoading={isLoading}
                isChecked={selectedDocuments.includes(document)}
                onChangeDocuments={onChangeDocuments}
                setPreviewDocument={setPreviewDocument}
              />
            ))
          )}
        </div>
      </div>
      <div className="-translate-x-6 w-[calc(100%+3rem)] px-4 pt-4 border-t border-t-blueGray-50">
        <div className="flex justify-between items-center">
          {isLoading ? (
            <p className="text-blueGray-500 text-sm font-medium animate-pulse">
              生成中...
            </p>
          ) : (
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
          )}
          <div className="flex flex-row space-x-4">
            <Button
              text={"戻る"}
              color={"gray"}
              variant={"contained"}
              onClick={onBack}
              disabled={isLoading}
            />
            <FormSubmitButton
              text={"次へ"}
              color={"primary"}
              variant={"contained"}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
