import React from "react";

import { Checkbox, Icon } from "@spesill/components/atoms";

import { useLearningDatabase } from "@spesill/hooks";
import { LearningDocument } from "@spesill/models";

type Props = {
  learningDocument: LearningDocument;
  isLoading?: boolean;
  onChangeDocuments: (learningDocument: LearningDocument) => void;
  setPreviewDocument: (arg: {
    dbName: string;
    document: LearningDocument;
  }) => void;
  isChecked: boolean;
};

export const DocumentItem = ({
  learningDocument,
  isLoading,
  onChangeDocuments,
  setPreviewDocument,
  isChecked,
}: Props) => {
  const { learningDatabase } = useLearningDatabase(
    learningDocument?.learningDatabaseId || "",
  );

  if (!learningDocument) {
    return null;
  }

  return (
    <div
      key={`file-${learningDocument.id}`}
      className={`flex items-center justify-between px-4 py-2.5 rounded-md ${!isChecked ? "border border-blueGray-50" : "border-primary-800 border-2"}`}
    >
      <div className="flex flex-row items-center gap-x-4">
        <Checkbox
          name={"file1"}
          checked={isChecked}
          onChange={() => onChangeDocuments(learningDocument)}
          size="md"
          disabled={isLoading}
        />
        {learningDocument.isExcel() ? (
          <Icon
            icon="riFileExcel2Fill"
            size="2rem"
            color="text-green-700"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
        ) : learningDocument.isPdf() ? (
          <Icon
            icon="tbPdf"
            size="2rem"
            color="text-status-danger"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
        ) : learningDocument.isImage() ? (
          <Icon
            icon="faImage"
            size="2rem"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
        ) : (
          <Icon
            icon="riFileWord2Fill"
            size="2rem"
            color="text-primary-400"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
        )}
        <div>
          <p className="text-sm font-bold text-blueGray-900 mb-1">
            {learningDocument.systemName}
          </p>
          <p className="text-xs text-blueGray-500">
            {learningDatabase?.systemName}
          </p>
        </div>
      </div>
      <button
        type="button"
        className="text-primary-800 text-sm"
        onClick={() =>
          setPreviewDocument({
            dbName: learningDatabase?.systemName || "",
            document: learningDocument,
          })
        }
      >
        プレビュー
      </button>
    </div>
  );
};
