import React, { useState } from "react";

import { Icon } from "@spesill/components/atoms";

export interface FileType {
  id: "image" | "video" | "document";
  icon: "faImage" | "faVideo" | "faFileAlt";
  label: string;
  accepts: string;
}

interface FileSelectorProps {
  onSelect: (fileType: FileType) => void;
  buttonSize?: number;
}

// FIXME: 各種対応後に随時コメントアウトをはずす
const fileTypes: FileType[] = [
  { id: "image", icon: "faImage", label: "画像", accepts: "image/*" },
  // { id: "video", icon: "faVideo", label: "動画", accepts: "video/*" },
  // {
  //   id: "document",
  //   icon: "faFileAlt",
  //   label: "文書",
  //   accepts: ".pdf,.doc,.docx,.txt",
  // },
];

export const ChatAddFile: React.FC<FileSelectorProps> = ({ onSelect }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (type: FileType): void => {
    onSelect(type);
    setIsOpen(false);
  };

  return (
    <div className="relative mr-2">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        aria-label="ファイルを添付"
        type="button"
      >
        <Icon icon="aiOutlinePlus" size="1rem" />
      </button>

      {isOpen && (
        <>
          {/* 背景をクリックで閉じる */}
          <button
            type="button"
            className="bg-transparent fixed top-0 bottom-0 left-0 right-0 z-0"
            onClick={() => setIsOpen(false)}
          />
          {/* ファイル種別選択メニュー */}
          <div
            className="absolute bottom-full mb-2 left-0 bg-white shadow-lg rounded-lg overflow-hidden border"
            role="menu"
          >
            <div className="p-2">
              {fileTypes.map((type) => (
                <button
                  key={type.id}
                  onClick={() => handleClick(type)}
                  className="flex items-center w-full px-4 py-2 hover:bg-gray-100 rounded transition-colors whitespace-nowrap"
                  role="menuitem"
                  type="button"
                >
                  <Icon icon={type.icon} className="mr-2" size="1rem" />
                  <span>{type.label}</span>
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatAddFile;
