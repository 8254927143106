import { useState, useCallback, useEffect } from "react";

import { apiClient } from "@spesill/libs/apiClient";
import { LearningDatabase, LearningDocument } from "@spesill/models";

type PropsType = {
  selectedDB: LearningDatabase[];
  documents: LearningDocument[];
  tenantId: string;
};

type ReturnType = {
  keywords: string[];
  selectedKeywords: string[];
  isLoadingKeywords: boolean;
  isLoadingDocuments: boolean;
  isShowMoreKeywords: boolean;
  filteredDocuments: LearningDocument[];
  setIsShowMoreKeywords: (value: boolean) => void;
  onChangeKeyword: (keyword: string) => Promise<void>;
};

export const useLearningDocumentsByKeywords = ({
  selectedDB,
  documents,
  tenantId,
}: PropsType): ReturnType => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [isShowMoreKeywords, setIsShowMoreKeywords] = useState<boolean>(false);
  const [isLoadingKeywords, setIsLoadingKeywords] = useState<boolean>(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);
  const [filteredDocuments, setFilteredDocuments] =
    useState<LearningDocument[]>(documents);

  const onChangeKeyword = useCallback(
    async (v: string) => {
      const newKeywords = selectedKeywords.includes(v)
        ? selectedKeywords.filter((keyword) => keyword !== v)
        : [...selectedKeywords, v];

      setSelectedKeywords(newKeywords);

      if (newKeywords.length === 0) {
        setFilteredDocuments(documents);
        return;
      }

      setIsLoadingDocuments(true);
      try {
        const res = await apiClient().search_documents.$post({
          body: {
            tenant_id: tenantId,
            group_ids: selectedDB.map((db) => db.id),
            keywords: newKeywords,
          },
        });
        if (res.documents) {
          const filtered = documents.filter((doc) =>
            res.documents.some((d) => d.firestore_document_id === doc.id),
          );
          setFilteredDocuments(filtered);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoadingDocuments(false);
      }
    },
    [selectedKeywords, tenantId, selectedDB, documents],
  );

  const getKeywords = useCallback(async () => {
    setIsLoadingKeywords(true);
    try {
      const res = await apiClient().get_keywords.$post({
        body: {
          tenant_id: tenantId,
          group_ids: selectedDB.map((db) => db.id),
        },
      });
      if (res.keywords.length > 0) {
        setKeywords(res.keywords);
        setIsShowMoreKeywords(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingKeywords(false);
    }
  }, [tenantId, selectedDB]);

  useEffect(() => {
    getKeywords();
  }, [getKeywords]);

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [documents]);

  return {
    keywords,
    selectedKeywords,
    isLoadingKeywords,
    isLoadingDocuments,
    isShowMoreKeywords,
    filteredDocuments,
    setIsShowMoreKeywords,
    onChangeKeyword,
  };
};
