import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { useStorageLoadGLBFromStorage } from "@spesill/hooks/storage/useStorageLoadGLBFromStorage";

type ViewMode = "normal" | "wireframe" | "basic";

type ThreeSceneProps = {
  meshPath: string;
  isVisible: boolean;
  viewMode?: ViewMode;
};

const setModelMaterial = (model: THREE.Group, mode: ViewMode) => {
  model.traverse((child) => {
    if (child instanceof THREE.Mesh) {
      switch (mode) {
        case "wireframe": {
          child.material = new THREE.MeshPhongMaterial({
            wireframe: true,
            color:
              child.material instanceof THREE.Material
                ? (child.material as THREE.MeshStandardMaterial).color?.clone()
                : new THREE.Color(0x4d4d4d),
          });
          break;
        }

        case "basic": {
          child.material = new THREE.MeshStandardMaterial({
            color:
              child.material instanceof THREE.Material
                ? (child.material as THREE.MeshStandardMaterial).color?.clone()
                : new THREE.Color(0x4d4d4d),
            roughness: 0.8,
            metalness: 0.1,
            flatShading: true,
          });
          break;
        }

        case "normal":
        default: {
          break;
        }
      }
    }
  });
};
export const ThreeScene: React.FC<ThreeSceneProps> = ({
  meshPath,
  isVisible,
  viewMode = "normal",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const controlsRef = useRef<OrbitControls | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const modelRef = useRef<THREE.Group | null>(null);
  const { loadGLB } = useStorageLoadGLBFromStorage();

  useEffect(() => {
    if (!isVisible || !containerRef.current) return;

    // シーンのセットアップ
    const container = containerRef.current;
    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const mainLight = new THREE.DirectionalLight(0xffffff, 1);
    mainLight.position.set(5, 5, 5);
    scene.add(mainLight);

    const fillLight = new THREE.DirectionalLight(0xffffff, 1);
    fillLight.position.set(-5, -5, -5);
    scene.add(fillLight);

    const bottomLight = new THREE.DirectionalLight(0xffffff, 1);
    bottomLight.position.set(0, -5, 0);
    scene.add(bottomLight);

    // カメラのセットアップ
    const camera = new THREE.PerspectiveCamera(
      45,
      container.offsetWidth / container.offsetHeight,
    );
    camera.position.set(3, 3, 3);
    camera.lookAt(scene.position);
    cameraRef.current = camera;

    // レンダラーのセットアップ
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    renderer.setClearColor(0x000000, 1);
    renderer.shadowMap.enabled = true;
    container.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // コントロールのセットアップ
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.minDistance = 2;
    controls.maxDistance = 10;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 1.0;
    controlsRef.current = controls;

    // モデルのロードと配置
    const loadModel = async () => {
      try {
        if (modelRef.current) {
          scene.remove(modelRef.current);
        }
        const model = await loadGLB(meshPath);

        setModelMaterial(model, viewMode);

        // モデルサイズを正規化
        const box = new THREE.Box3().setFromObject(model);
        const size = box.getSize(new THREE.Vector3());
        const maxSize = Math.max(size.x, size.y, size.z);
        const scale = 2 / maxSize;
        model.scale.setScalar(scale);

        // モデルを中心に配置
        const center = box.getCenter(new THREE.Vector3());
        model.position.sub(center.multiplyScalar(scale));

        scene.add(model);
        modelRef.current = model;
      } catch (error) {
        console.error("Failed to load model:", error);
      }
    };
    loadModel();

    // アニメーションループ
    const animate = () => {
      if (!isVisible) return;
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };
    animate();

    // リサイズ対応
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        if (camera && renderer) {
          camera.aspect = width / height;
          camera.updateProjectionMatrix();
          renderer.setSize(width, height);
        }
      }
    });
    resizeObserver.observe(container);
    resizeObserverRef.current = resizeObserver;

    // クリーンアップ
    return () => {
      resizeObserver.disconnect();
      if (renderer && container) {
        container.removeChild(renderer.domElement);
        renderer.dispose();
      }
      if (modelRef.current) {
        scene.remove(modelRef.current);
      }
      scene.remove(ambientLight, mainLight, fillLight, bottomLight);
      controls.dispose();
    };
  }, [isVisible, loadGLB, meshPath, viewMode]);

  useEffect(() => {
    if (modelRef.current) {
      setModelMaterial(modelRef.current, viewMode);
    }
  }, [viewMode]);

  return <div ref={containerRef} className="w-full h-full" />;
};

export default ThreeScene;
