import { useCallback, useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";

import { privateStorage } from "@spesill/config/firebase";
import {
  getBlob,
  ref,
  getDownloadURL,
  StorageReference,
} from "@spesill/libs/firebase";

type ReturnType = {
  blob: Blob | undefined;
  file: File | undefined;
  url: string | undefined;
  setFile: (value: File | undefined) => void;
};

export const useStorageDownloadBlob = (
  pathName: string,
  isErrorDisplayed = true,
): ReturnType => {
  const [blob, setBlob] = useState<Blob>();
  const [file, setFile] = useState<File>();
  const [url, setUrl] = useState<string>();

  const storageRef: StorageReference = useMemo(
    () => ref(privateStorage, pathName),
    [pathName],
  );

  const fetchBlob = useCallback(() => {
    if (!pathName) return;
    getBlob(storageRef)
      .then((blob) => {
        setBlob(blob);
        setFile(new File([blob], pathName));
        getDownloadURL(storageRef).then((downloadUrl) => {
          setUrl(downloadUrl);
        });
      })
      .catch((error) => {
        console.error(error);
        if (isErrorDisplayed) {
          toast.warn("ファイルが見つかりませんでした。");
        }
        setBlob(undefined);
        setFile(undefined);
        setUrl(undefined);
      });
  }, [pathName, storageRef, isErrorDisplayed]);

  useEffect(() => {
    pathName && fetchBlob();
  }, [fetchBlob, pathName]);

  return {
    blob,
    file,
    url,
    setFile,
  };
};
