import React, { useState, useEffect } from "react";

import { Icon } from "@spesill/components/atoms";

type FileType = "image" | "video" | "document";

interface ChatFilePreviewProps {
  file: File;
  onRemove: () => void;
}

const getFileType = (file: File): FileType => {
  if (file.type.startsWith("image/")) return "image";
  if (file.type.startsWith("video/")) return "video";
  return "document";
};

const ImagePreview: React.FC<{ url: string }> = ({ url }) => (
  <img
    src={url}
    alt="Preview"
    className="max-h-16 max-w-32 rounded-md object-contain"
  />
);

const VideoPreview: React.FC<{ url: string }> = ({ url }) => (
  <div className="relative w-32 h-16 bg-gray-100 rounded-md flex items-center justify-center">
    <div className="absolute inset-0">
      <video
        src={url}
        className="w-full h-full rounded-md object-cover"
        onLoadedMetadata={(e) => {
          const video = e.target as HTMLVideoElement;
          video.currentTime = 0;
        }}
      >
        <track kind="captions" src="" label="キャプション" default />
      </video>
    </div>
    <Icon icon="faVideo" className="text-white text-xl z-10" />
  </div>
);

const DocumentPreview: React.FC<{ filename: string }> = ({ filename }) => (
  <div className="w-32 h-16 bg-gray-100 rounded-md flex flex-col items-center justify-center p-2">
    <Icon icon="faFileAlt" className="text-gray-400 text-2xl mb-1" />
    <div className="text-xs text-gray-600 truncate w-full text-center">
      {filename}
    </div>
  </div>
);

export const ChatFilePreview: React.FC<ChatFilePreviewProps> = ({
  file,
  onRemove,
}) => {
  const [blobUrl, setBlobUrl] = useState<string>("");
  const fileType = getFileType(file);

  useEffect(() => {
    if (file && (fileType === "image" || fileType === "video")) {
      const url = URL.createObjectURL(file);
      setBlobUrl(url);
      console.log("Created new blob URL:", url);

      return () => {
        console.log("Revoking URL:", url);
        URL.revokeObjectURL(url);
      };
    }
  }, [file, fileType]);

  return (
    <div className="bg-gray-50/80 rounded-lg p-2 inline-flex items-center">
      <div className="relative">
        {fileType === "image" && blobUrl && <ImagePreview url={blobUrl} />}
        {fileType === "video" && blobUrl && <VideoPreview url={blobUrl} />}
        {fileType === "document" && <DocumentPreview filename={file.name} />}

        <button
          onClick={onRemove}
          className="absolute -top-2 -right-2 rounded-full bg-gray-100 p-1 hover:bg-gray-200 shadow-sm"
          aria-label="ファイルを削除"
          type="button"
        >
          <Icon icon="ioClose" size={"1rem"} className="text-gray-600" />
        </button>
      </div>
    </div>
  );
};

export default ChatFilePreview;
