import React, { FC, ChangeEvent, MouseEvent } from "react";

import { Icon, IconType, TextArea } from "@spesill/components/atoms";

type FieldType = "text" | "email" | "password" | "search";

type PropsType = {
  icon: IconType;
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  outline?: boolean;
  disabled?: boolean;
  disabledButton?: boolean;
  className?: string;
  rows?: number;
  maxRows?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
};

export const ButtonWithTextArea: FC<PropsType> = ({
  icon,
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  disabledButton = false,
  className = "",
  rows,
  maxRows,
  onChange,
  onClick,
  onKeyDown,
}: PropsType) => {
  return (
    <div
      className={`relative ${className} ${
        disabled ? "cursor-not-allowed" : ""
      }`}
    >
      <TextArea
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={onChange}
        rows={rows}
        maxRows={maxRows}
        onKeyDown={onKeyDown}
      />
      <button
        type="button"
        onClick={onClick}
        disabled={disabledButton}
        className={`absolute inset-y-0 right-3 px-5 ${
          disabledButton ? "cursor-not-allowed" : ""
        }`}
      >
        <Icon icon={icon} size="1.5rem" color="text-blueGray-400" />
      </button>
    </div>
  );
};
