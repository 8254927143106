import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

import { DropzoneFileField } from "@spesill/components/atoms";
import { ACCEPT_CHAT_FILE_TYPES } from "@spesill/components/atoms/DropzoneFileField/constants";
import { ButtonWithTextArea } from "@spesill/components/molecules";
import {
  MessageBox,
  UpdateChatLearningDatabaseModal,
} from "@spesill/components/organisms";
import {
  ChatAddFile,
  FileType,
} from "@spesill/components/organisms/Chat/AddFile/ChatAddFile";
import { ChatFilePreview } from "@spesill/components/organisms/Chat/AddFile/ChatFilePreview";

import {
  useBoolean,
  useChatRoom,
  useLearningDatabasesWithUser,
  useChatRoomMessages,
  useInput,
  useCurrentUser,
  useIncrementAiUsage,
} from "@spesill/hooks";
import { useStorageUploadFileInChat } from "@spesill/hooks/storage/useStorageUploadFileInChat";
import { sseClient } from "@spesill/libs/sseClient";
import { ChatRoomMessage } from "@spesill/models";

import { TabSelector } from "./ TabSelector ";
import ChatDetailSuggestions from "./Suggestions";
import TemplateSelector from "./TemplateSelector";
import SuggestionButton from "../SuggestionButton";

type PropsType = {
  chatId: string;
};

enum ActiveTab {
  LearningDatabase,
  ChatGPT,
}
export const ChatDetail: React.FC<PropsType> = ({ chatId }) => {
  const { currentUser } = useCurrentUser();
  const [{ value, onChange, resetValue, setValue }] = useInput();
  const { learningDatabases } = useLearningDatabasesWithUser();
  const { fetchChatRoom, chatRoom } = useChatRoom(chatId);
  const { incrementAiCallCount } = useIncrementAiUsage();
  const { chatRoomMessages, fetchChatRoomMessages } =
    useChatRoomMessages(chatId);
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.ChatGPT);

  const [messages, setMessages] = useState<ChatRoomMessage[]>([]);
  const [streamingMessage, setStreamingMessage] =
    useState<ChatRoomMessage | null>(null);

  const {
    isChecked: isUpdateChatLearningDatabaseModal,
    setTrue: setUpdateChatLearningDatabaseModalOpen,
    setFalse: setUpdateChatLearningDatabaseModalClose,
  } = useBoolean(false);

  const { isChecked: isStreaming, setTrue, setFalse } = useBoolean(false);

  const {
    isChecked: isImage,
    setTrue: setImgTrue,
    setFalse: setImgFalse,
  } = useBoolean(false);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { uploadFile } = useStorageUploadFileInChat();

  // TODO: 学習DB選択時もファイル添付で生成できるようになったら削除
  const handleSetActiveTab = (tab: ActiveTab) => {
    if (selectedFile && tab === ActiveTab.LearningDatabase) {
      toast.warn("ファイルを添付した場合はChatGPTモードのみ利用可能です。");
      setActiveTab(ActiveTab.ChatGPT);
    } else {
      setActiveTab(tab);
    }
  };

  const handleSetFile = (file?: File) => {
    if (file) {
      setSelectedFile(file);
      setActiveTab(ActiveTab.ChatGPT); // ファイル添付時はChatGPTモードのみ
      const type = ChatRoomMessage.getFileType(file.name);
      type === "image" && setImgTrue();
    }
  };

  const handleFileSelect = async (fileType: FileType) => {
    const input = document.createElement("input");
    input.type = "file";

    // ファイルタイプに応じて accept 属性を設定
    // FIXME: アップロード可能な拡張子は一箇所で管理する
    switch (fileType.id) {
      case "image":
        input.accept = ".png,.jpg,.jpeg,.gif";
        break;
      case "video":
        input.accept = "video/*";
        break;
      case "document":
        input.accept = ".pdf,.doc,.docx,.txt";
        break;
    }

    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      handleSetFile(file);
    };

    input.click();
  };

  const handleDropFile = (files: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    const file = files[0];
    handleSetFile(file);
  };

  const uploadAndSendMessage = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!currentUser || !chatRoom) return;
    try {
      let uploadedPath: string | undefined;
      if (selectedFile !== null) {
        uploadedPath = `tenants/${currentUser.tenantId}/attached_data/${chatRoom.id}/image/${selectedFile.name}`;
        const result = await uploadFile(uploadedPath, selectedFile);
        if (!result.success) {
          toast.error("ファイルのアップロードに失敗しました");
          return;
        }
      }
      setSelectedFile(null);
      await onSendMessage(e, uploadedPath);
    } catch (error) {
      console.error("エラーが発生しました:", error);
      toast.error("処理中にエラーが発生しました");
    }
  };

  const onSendMessage = async (
    e: MouseEvent<HTMLButtonElement>,
    path: string | undefined,
  ) => {
    e.preventDefault();
    if (!currentUser || !chatRoom) return;

    if (!value) {
      toast.error("メッセージを入力してください");
      return;
    }
    const newMessage = {
      id: "streaming",
      sender: "USER",
      message: value,
      createdAt: new Date(),
      updatedAt: new Date(),
    } as ChatRoomMessage;

    setMessages((prev) => [...prev, newMessage]);
    setTrue();
    resetValue();
    try {
      await sseClient.streamResponse(
        "ai_chat",
        {
          user_id: currentUser.id,
          chat_room_id: chatRoom.id,
          tenant_id: currentUser.tenantId,
          group_id:
            activeTab === ActiveTab.LearningDatabase
              ? chatRoom.learningDatabaseId || ""
              : "",
          question: value,
          with_image: isImage,
          with_movie: false,
          media_contents_path: path,
        },
        (event) => {
          try {
            setStreamingMessage(
              (prev) =>
                ({
                  id: "streaming",
                  sender: "AI",
                  message: prev ? prev.message + event.data : event.data,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                }) as ChatRoomMessage,
            );
          } catch (e) {
            console.log(e);
          }
        },
        (error) => {
          console.error("ストリーミングエラー:", error);
          toast.error("メッセージの受信中にエラーが発生しました");
          setFalse();
          handleRemove();
          setMessages((prev) => prev.slice(0, -1));
        },
      );
      incrementAiCallCount("aiChat");
      fetchChatRoomMessages();
      handleRemove();
    } catch (error) {
      console.error("API呼び出しエラー:", error);
      toast.error("メッセージの送信中にエラーが発生しました");
      setFalse();
      handleRemove();
      setMessages((prev) => prev.slice(0, -1));
    } finally {
      setFalse();
      setStreamingMessage(null);
      handleRemove();
    }
  };

  const selectedLearningDatabase = useMemo(
    () =>
      learningDatabases.find(
        (learningDatabase) =>
          learningDatabase.id === chatRoom?.learningDatabaseId,
      ),
    [learningDatabases, chatRoom],
  );

  const isSelectingTable = useMemo(
    () =>
      activeTab === ActiveTab.LearningDatabase &&
      selectedLearningDatabase?.structureType === "table",
    [activeTab, selectedLearningDatabase],
  );

  useEffect(() => {
    setMessages(chatRoomMessages);
  }, [chatRoomMessages]);

  const handleRemove = () => {
    setSelectedFile(null);
    setImgFalse();
  };

  return (
    <div className="w-full flex flex-col h-full max-h-screen">
      <div className="h-full flex flex-col p-4 overflow-auto">
        <ul className="w-full h-full pb-20">
          {messages.length === 0 && (
            <MessageBox
              isMe={false}
              isInitial={true}
              message="こんにちは。このチャットではChat-GPTもしくはデータベースに蓄積された文書データをもとに、AIがあなたの課題や知識の抽出をサポートします。"
            />
          )}
          {messages.map((chatRoomMessage) => (
            <MessageBox
              key={chatRoomMessage.id}
              isMe={chatRoomMessage.sender === "USER"}
              lastName={currentUser?.lastName}
              message={chatRoomMessage.message}
              sources={chatRoomMessage.sources}
            />
          ))}
          {isStreaming && (
            <MessageBox
              isMe={false}
              message={streamingMessage?.message || ""}
              isStreaming={isStreaming}
            />
          )}
        </ul>
        {messages.length === 0 && isSelectingTable && (
          <div className="flex flex-col gap-3 pb-6">
            <SuggestionButton
              onClick={() =>
                setValue(
                  "<項目名>に〇〇が含まれているデータの<項目名>を抽出して下さい",
                )
              }
            >
              <span className="text-primary-400">{"<項目名>"}</span>
              に〇〇が含まれているデータの
              <span className="text-primary-400">{"<項目名>"}</span>
              を抽出して下さい
            </SuggestionButton>
            <SuggestionButton
              onClick={() => setValue("<項目名>が〇〇の<項目名>を教えて下さい")}
            >
              <span className="text-primary-400">{"<項目名>"}</span>
              が〇〇の
              <span className="text-primary-400">{"<項目名>"}</span>
              を教えて下さい
            </SuggestionButton>
          </div>
        )}
      </div>
      <DropzoneFileField
        onDropFiles={handleDropFile}
        acceptFileTypes={ACCEPT_CHAT_FILE_TYPES}
        noClick
      >
        <div className="flex flex-col sticky bottom-0 bg-white">
          <TabSelector
            activeTab={activeTab}
            selectedLearningDatabase={selectedLearningDatabase}
            setActiveTab={handleSetActiveTab}
            setUpdateChatLearningDatabaseModalOpen={
              setUpdateChatLearningDatabaseModalOpen
            }
          />
          {selectedFile && (
            <ChatFilePreview
              file={selectedFile}
              onRemove={handleRemove} // 削除ボタンが押された時の処理
            />
          )}

          <form className="p-4 flex flex-col items-center gap-2">
            <div className="flex w-full">
              <ChatAddFile onSelect={handleFileSelect} />
              <div className="flex-1 min-w-0">
                <ButtonWithTextArea
                  name="question"
                  type="text"
                  icon="ioMdSend"
                  className="border-none bg-white outline-0"
                  placeholder="メッセージを入力してください"
                  required
                  disabledButton={!value || isStreaming}
                  value={value}
                  outline={false}
                  onChange={onChange}
                  onClick={(e) => uploadAndSendMessage(e)}
                  onKeyDown={(e) => {
                    if (isStreaming || e.nativeEvent.isComposing) return;
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      uploadAndSendMessage(
                        e as unknown as MouseEvent<HTMLButtonElement>,
                      );
                    }
                  }}
                  rows={1}
                  maxRows={15}
                />
              </div>
            </div>
            {isSelectingTable && (
              <TemplateSelector
                isSelectingTable={isSelectingTable}
                messages={messages}
                setValue={setValue}
              />
            )}
            {selectedLearningDatabase &&
              activeTab === ActiveTab.LearningDatabase && (
                <ChatDetailSuggestions
                  tenantId={currentUser?.tenantId}
                  groupId={selectedLearningDatabase?.id}
                  setValue={setValue}
                />
              )}
          </form>
        </div>
      </DropzoneFileField>
      {isUpdateChatLearningDatabaseModal && chatRoom && (
        <UpdateChatLearningDatabaseModal
          onClose={setUpdateChatLearningDatabaseModalClose}
          chatRoom={chatRoom}
          onSuccess={() => {
            fetchChatRoom();
            handleSetActiveTab(ActiveTab.LearningDatabase);
          }}
        />
      )}
    </div>
  );
};
