import React, { useState } from "react";

import DialogWindow from "@spesill/components/molecules/DialogWindow/DialogWindow";

import ThreeScene from "./ThreeScene";

type MeshViewerDialogProps = {
  meshPath: string;
  title?: string;
  initialWidth?: number;
  initialHeight?: number;
  minWidth?: number;
  minHeight?: number;
};

export const MeshViewerDialog: React.FC<MeshViewerDialogProps> = ({
  meshPath,
  title = "3Dビューワー",
  initialWidth = 600,
  initialHeight = 500,
  minWidth = 400,
  minHeight = 300,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [viewMode, setViewMode] = useState<"normal" | "wireframe" | "basic">(
    "normal",
  );

  const trigger = (
    <button
      type="button"
      onClick={() => setIsVisible(true)}
      className="group relative px-6 py-3 bg-blue-500 text-white rounded-lg
                  shadow-lg hover:bg-blue-600 hover:shadow-xl
                  transform transition-all duration-200 hover:-translate-y-0.5
                  flex items-center justify-center gap-2 font-medium"
    >
      <svg
        className="w-5 h-5 transition-transform group-hover:translate-y-0.5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
        />
      </svg>
      3Dモデルを表示
    </button>
  );

  const headerContent = (
    <div className="flex gap-2">
      <button
        type="button"
        onClick={() => setViewMode("normal")}
        className={`px-2 py-1 rounded ${
          viewMode === "normal" ? "bg-blue-500 text-white" : "bg-gray-200"
        }`}
      >
        通常
      </button>
      <button
        type="button"
        onClick={() => setViewMode("wireframe")}
        className={`px-2 py-1 rounded ${
          viewMode === "wireframe" ? "bg-blue-500 text-white" : "bg-gray-200"
        }`}
      >
        ワイヤーフレーム
      </button>
      <button
        type="button"
        onClick={() => setViewMode("basic")}
        className={`px-2 py-1 rounded ${
          viewMode === "basic" ? "bg-blue-500 text-white" : "bg-gray-200"
        }`}
      >
        シンプル
      </button>
    </div>
  );

  return (
    <DialogWindow
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      title={title}
      initialWidth={initialWidth}
      initialHeight={initialHeight}
      minWidth={minWidth}
      minHeight={minHeight}
      headerContent={headerContent}
      trigger={trigger}
    >
      <ThreeScene
        meshPath={meshPath}
        isVisible={isVisible}
        viewMode={viewMode}
      />
    </DialogWindow>
  );
};

export default MeshViewerDialog;
