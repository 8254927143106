/**
 * パスワードポリシーを満たしているかを検証する
 * @see [パスワードポリシー](https://www.notion.so/firstam/13258fc1f04349c398f75195acd3bb8e)
 */

export const PASSWORD_MIN_LENGTH = 13;
export const PASSWORD_MAX_LENGTH = 128;

export const specialCharacters =
  "^ $ * . { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =";

export const validatePassword = (password: string) => {
  if (password.length < PASSWORD_MIN_LENGTH) return false;
  if (password.length > PASSWORD_MAX_LENGTH) return false;

  const hasLowerCaseLetter = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[-+_!@#$%^&*.,?]/.test(password);

  return hasLowerCaseLetter && hasNumber && hasSpecialChar;
};

export const passwordRequirementsMessage: string[] = [
  "以下の要件をすべて満たしたパスワードを設定してください。",
  `・${PASSWORD_MIN_LENGTH}文字〜${PASSWORD_MAX_LENGTH}文字の半角英数字`,
  "・1文字以上の数字を含む",
  "・1文字以上の英小文字を含む",
  `・1文字以上の特殊文字（${specialCharacters}）を含む`,
];
