import React from "react";
import { toast } from "react-toastify";

import Image from "next/image";

import {
  DotLoadingAnimation,
  InitialNameIcon,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";

import { useStorageDownloadBlob } from "@spesill/hooks";
import { useDownloadFileFromStorage } from "@spesill/hooks/storage/useDownloadFileFromStorage";
import { ChatRoomMessage } from "@spesill/models";
import { SourceType } from "@spesill/models/source";

import { MarkdownRenderer } from "../..";
import DownloadButton from "../../Button/DownloadButton";
import { DocumentSource } from "../../Document/ConvertedHTMLDetail/DocumentHeadingTitleForm/DocumentInsertTextForm/DocumentSource";
import Base64ImageViewer from "../ChatImage/ConvertImage";
import { MeshViewerDialog } from "../MeshViewer/MeshViewer";

type PropsType = {
  isMe?: boolean;
  isStreaming?: boolean;
  isImage?: boolean;
  isMesh?: boolean;
  lastName?: string;
  message: string;
  sources?: SourceType[];
  isInitial?: boolean;
};

const extractStoragePath = (text: string): string => {
  // 'tenants/'で始まるパスにマッチする正規表現
  const regex = /tenants\/[^\s\n]*/; // gフラグを削除
  // マッチした最初のパスを返す
  const match = text.match(regex);

  return match ? match[0] : ""; // マッチしない場合は空文字を返す
};

export const MessageBox = ({
  isMe = false,
  isInitial = false,
  isStreaming = false,
  message,
  lastName = "",
  sources,
}: PropsType) => {
  const path = extractStoragePath(message);
  const { url } = useStorageDownloadBlob(path, false);
  const { downloadFile } = useDownloadFileFromStorage();

  const handleCopy = () => {
    navigator.clipboard
      .writeText(message || "")
      .then(() => toast.success("テキストをコピーしました"))
      .catch(() => toast.error("コピーに失敗しました"));
  };

  const getMessageComponent = () => {
    if (path !== "") {
      const type = ChatRoomMessage.getFileType(path);
      if (type === "image") {
        return url ? (
          <Base64ImageViewer
            base64Data={url}
            altText={isMe ? "入力画像" : "出力画像"}
            onDownload={() => downloadFile(path)}
          />
        ) : (
          <div>画像を読み込み中...</div>
        );
      } else if (type === "glb") {
        const text = `生成したモデルが準備できました。以下のボタンからダウンロードできます。
        ダウンロード後はリンクからモデルを確認できます：https://gltf-viewer.donmccurdy.com/`;

        return (
          <div>
            <MarkdownRenderer text={text} />
            <div className="flex gap-4 mt-4">
              <DownloadButton downloadFile={() => downloadFile(path)} />
              <MeshViewerDialog
                meshPath={path}
                title="3Dモデルビューワー"
                initialWidth={700}
                initialHeight={600}
                minWidth={300}
                minHeight={200}
              />
            </div>
          </div>
        );
      }
    }
    return <MarkdownRenderer text={message} />;
  };

  return (
    <li className="flex flex-row gap-2 items-start w-full text-sm">
      {isMe ? (
        <InitialNameIcon className="shrink-0 w-10 h-10" name={lastName} />
      ) : (
        <Image
          src="/logo-circle.png"
          alt="SPESILL LOGO"
          width="40"
          height="40"
          className={`${isStreaming ? "animate-bounce" : ""}`}
        />
      )}

      <div
        className={`p-6 rounded-r-lg rounded-bl-lg w-full mt-5 whitespace-pre-wrap ${
          isMe ? "bg-white" : "bg-primary-50"
        }`}
      >
        {getMessageComponent()}
        {isStreaming && message.length === 0 && <DotLoadingAnimation />}
        {isStreaming && message.length > 0 && (
          <span className="inline-block animate-pulse">●</span>
        )}
        {/* ストリーミング中でない場合は、ソースを表示 */}
        {!isStreaming && sources && sources?.length > 0 && (
          <DocumentSource sources={sources} className="mt-4" />
        )}
        {/* ストリーミング中でない場合は、コピーボタンを表示 */}
        {!isMe && !isInitial && !isStreaming && (
          <div className="flex items-end justify-end">
            <IconButton
              icon={{
                icon: "mdContentCopy",
                size: "1.25rem",
                color: "text-blueGray-500",
              }}
              onClick={handleCopy}
              tooltip={{
                text: "コピーする",
                direction: "top",
                className: "text-xs",
              }}
            />
          </div>
        )}
      </div>
    </li>
  );
};
