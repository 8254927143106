import React from "react";

import DownloadButton from "../../Button/DownloadButton";

type Base64ImageViewerProps = {
  base64Data: string;
  altText: string;
  onDownload: () => void;
};

const Base64ImageViewer: React.FC<Base64ImageViewerProps> = ({
  base64Data,
  altText,
  onDownload,
}) => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>{altText}</h1>
      {base64Data ? (
        <div>
          <img src={base64Data} alt={altText} style={{ maxWidth: "40%" }} />
          <DownloadButton downloadFile={onDownload} className="mt-4" />
        </div>
      ) : (
        <p>画像データが提供されていません。</p>
      )}
    </div>
  );
};

export default Base64ImageViewer;
