import { useState, useEffect, useCallback } from "react";

import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { LearningDocument } from "@spesill/models";
import { LearningDocumentRepository } from "@spesill/repository/db/learningDocument.repository";

const learningDocumentRepository = new LearningDocumentRepository();

export const useFetchLearningDocuments = (learningDatabaseIds: string[]) => {
  const [documents, setDocuments] = useState<LearningDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { currentUser } = useCurrentUser();

  const fetchDocuments = useCallback(async () => {
    try {
      setLoading(true);
      const docs =
        await learningDocumentRepository.findByTenantIdAndLearningDatabaseIds(
          currentUser?.tenantId || "",
          learningDatabaseIds,
        );
      setDocuments(docs);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [currentUser?.tenantId, learningDatabaseIds]);

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { documents, loading };
};
