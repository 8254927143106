import React, { useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useStorageDownloadBlob } from "@spesill/hooks";
import { PdfLocationType } from "@spesill/models/source";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

type PropsType = {
  path: string;
  location?: PdfLocationType[];
  file?: File;
};

export const PdfFileViewer = ({ path, location, file }: PropsType) => {
  const { url } = useStorageDownloadBlob(path);
  const [numPages, setNumPages] = useState(0);
  const [pdfUrl, setPdfUrl] = useState(url);

  // PDF.jsのWorkerのソースファイルの場所を設定
  // https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md#configure-pdfjs-worker
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url,
  ).toString();

  const pdfOptions = useMemo(
    () => ({
      cMapUrl: "https://unpkg.com/pdfjs-dist@3.11.174/cmaps/",
      cMapPacked: true,
    }),
    [],
  );

  // file または url が変化したときに PDF の URL をセット
  useEffect(() => {
    if (file && !url) {
      const objectUrl = URL.createObjectURL(file);
      setPdfUrl(objectUrl);

      // クリーンアップ時に不要になった URL を解放
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else {
      // file がなければサーバーから取得した url を使う
      setPdfUrl(url);
    }
  }, [file, url]);

  return (
    <div className="flex justify-center bg-blueGray-50  max-h-[90vh] overflow-y-auto">
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        onLoadError={(e) => {
          console.log(e);
        }}
        options={pdfOptions}
      >
        {[...Array(numPages)].map((_, index) => (
          <div key={`page_${index + 1}`} className="relative">
            <Page
              pageNumber={index + 1}
              inputRef={(ref) => {
                if (!location || index + 1 !== Number(location[0]?.page_no)) {
                  return;
                }
                ref?.scrollIntoView();
              }}
            />
            {location
              ?.filter((l) => Number(l.page_no) === index)
              .map((l, locIndex) => (
                <div
                  key={`location_${index + 1}_${locIndex}`}
                  className="absolute bg-primary-800 opacity-50"
                  style={{
                    top: `${l.y * 100}%`,
                    left: `${l.x * 100}%`,
                    width: `${l.width * 100}%`,
                    height: `${l.height * 100}%`,
                  }}
                />
              ))}
          </div>
        ))}
      </Document>
    </div>
  );
};
