import { useCallback } from "react";

import { privateStorage } from "@spesill/config/firebase";
import { ref, getDownloadURL } from "@spesill/libs/firebase";

export const useDownloadFileFromStorage = () => {
  const downloadFile = useCallback(async (storagePath: string) => {
    try {
      // ストレージの参照を取得
      const fileRef = ref(privateStorage, storagePath);

      // ダウンロードURLを取得
      const url = await getDownloadURL(fileRef);

      // ファイルをダウンロード
      const response = await fetch(url);
      const blob = await response.blob();

      // ダウンロードリンクを作成
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = storagePath.split("/").pop() || "downloaded-file";

      // ダウンロードを実行
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // クリーンアップ
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("ファイルのダウンロード中にエラーが発生しました:", error);
    }
  }, []);

  return { downloadFile };
};
